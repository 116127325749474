export enum CourseStatus {
  Open = "åpnet",
  Interested = "interessert",
  Applied = "søkt",
  Approved = "godkjent",
  SignedUp = "påmeldt",
  WaitingList = "venteliste",
  Denied = "avslått",
  Unsubscribed = "avmeldt",
  Qualified = "kvalifisert",
  LeaveOfAbsence = "permisjon",
  Plannet = "planlagt",
  Postponed = "utsatt",
  Canceled = "innstilt",
  Deleted = "slettet",
  Finished = "fullført",
  Closed = "avsluttet",
}
