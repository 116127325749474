








































































































import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent } from "@vue/composition-api";
import { lastDayOfYear } from "date-fns";
import { commonProps } from "./commonProps";

export default defineComponent({
  name: "GrantApplicationForm",
  components: { BaseDatePicker },
  props: {
    ...commonProps,
  },
  emits: ["grantChange", "validate"],
  setup(props, { emit, refs }) {
    const grantValues = useAutoDeepCloneProp(props, "facilitationGrant");
    const disabled = useAutoDeepCloneProp(props, "disabled");
    const validate = () => {
      const isValid = !!getValidatableRef(refs.form)?.validate();
      emit("validate", isValid);
    };
    const emitChanges = () => {
      emit("grantChange", grantValues.value);
    };
    const latestDateOfCourseYear = computed(() => {
      if (!props.course?.endDate) {
        return;
      }
      return lastDayOfYear(new Date(props.course?.endDate)).toISOString();
    });

    return {
      emitChanges,
      validate,
      grantValues,
      disabled,
      validateNotEmpty,
      latestDateOfCourseYear,
    };
  },
});
