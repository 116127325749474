var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.grantValues.budgetLines,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.columns.map(function (ref) {
	var value = ref.value;

	return value;
})),function(column){return {key:("header." + column),fn:function(ref){
var header = ref.header;
return [_c('div',{key:("template-header-" + column)},[_vm._v(" "+_vm._s(header.text)+" ")])]}}}),_vm._l((_vm.columns.map(function (ref) {
	var value = ref.value;

	return value;
})),function(column){return {key:("item." + column),fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('div',{key:("template-" + column)},[(header.editable && item.budgetLineType !== undefined)?_c('v-text-field',{attrs:{"suffix":"kr","reverse":"","hide-details":"","dense":""},on:{"input":_vm.emitChanges},model:{value:(item[column]),callback:function ($$v) {_vm.$set(item, column, _vm._n($$v))},expression:"item[column]"}}):_c('div',[_vm._v(" "+_vm._s(_vm.formatCurrency(item[column]))+" ")])],1)]}}}),{key:"item.budgetLineType",fn:function(ref){
var ref_item = ref.item;
var budgetLineType = ref_item.budgetLineType;
var description = ref_item.description;
return [_c('div',[_vm._v(_vm._s(description || _vm.getDescription(budgetLineType)))])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"font-weight-bold"},[_c('td',[_vm._v("Sum tilrettelegging")]),_vm._l((_vm.visibleBudgetColumns),function(column){return _c('td',{key:("sum-cost-" + (column.value)),staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.sumCostType(column.value, _vm.grantValues.budgetLines)))+" ")])})],2)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }