















































































import { api } from "@/api/api";
import { ApiGetFacilitationGrantApplicationDto } from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import GrantApplicationDetails from "@/components/course/details/facilitationGrants/GrantApplicationDetails.vue";
import GrantApplicationForm from "@/components/course/details/facilitationGrants/GrantApplicationForm.vue";
import GrantApplicationSummary from "@/components/course/details/facilitationGrants/GrantApplicationSummary.vue";
import GrantBudgetComment from "@/components/course/details/facilitationGrants/GrantBudgetComment.vue";
import GrantDocumentation from "@/components/course/details/facilitationGrants/GrantDocumentation.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { GrantApplicationStatus } from "@/shared/enums/GrantApplicationStatus.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { sumCostType } from "@/shared/helpers/courseHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { commonProps } from "./commonProps";
import GrantBudgetTable from "@/components/course/details/facilitationGrants/GrantBudgetTable.vue";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "GrantApplicationStepper",
  props: {
    ...commonProps,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();
    const grantValues = useAutoDeepCloneProp(props, "facilitationGrant");
    const updateGrant = (grant: ApiGetFacilitationGrantApplicationDto) => {
      grantValues.value = grant;
    };
    const isApplicationValid = ref(false);

    const applicationStatus = computed(() => {
      if (!props.course) {
        return undefined;
      }
      if (props.course?.facilitationGrantApplication === undefined) {
        return GrantApplicationStatus.None;
      }
      return props.course?.facilitationGrantApplication?.applicationStatus as GrantApplicationStatus;
    });

    const isApplicationStatus = (...statusList: GrantApplicationStatus[]) =>
      applicationStatus.value && statusList.includes(applicationStatus.value);

    const isExtendedCourseAdmin = authService.hasRoles(AuthRole.ModifyExtendedCourse);

    const steps = computed((): GrantStep[] =>
      [
        {
          title: "Søknadsskjema",
          locked: !isApplicationStatus(GrantApplicationStatus.None),
          valid: isApplicationValid.value,
          disabled: props.course?.status === CourseStatus.Closed,
          content: [
            {
              component: GrantApplicationForm,
              eventHandlers: {
                validate: (isValid: boolean) => {
                  isApplicationValid.value = isValid;
                },
              },
            },
          ],
        },
        {
          title: "Budsjett",
          subtitle: "Søknaden oversendes saksbehandler etter utfylling av budsjett",
          hidden: !isApplicationStatus(GrantApplicationStatus.None) && !isExtendedCourseAdmin,
          locked: !isApplicationValid.value,
          content: [
            {
              component: GrantApplicationDetails,
            },
            {
              component: GrantBudgetTable,
              props: {
                visibleColumns: ["amountAppliedFor"],
                editableColumns: ["amountAppliedFor"],
              },
            },
          ],
          // props: {
          //   rules: [() => false], // TODO sett til false om ikke godkjent
          // },
          actions: [
            {
              label: "Forrige steg",
              eventHandlers: {
                click: prevStep,
              },
              props: {
                outlined: true,
                class: "mr-4",
              },
            },
            {
              label: "Send til godkjenning",
              iconRight: "mdi-send",
              eventHandlers: {
                click: async () => {
                  await api.course.createGrantApplication(props.courseId, grantValues.value);
                  openNotification(
                    store,
                    NotificationItemType.Success,
                    "Søknad om tilretteleggingstilskudd er sendt til godkjenning"
                  );
                  nextStep(); // FIXME redirect to dashboard?
                },
              },
              props: {
                color: "primary",
                disabled: sumCostType("amountAppliedFor", grantValues.value.budgetLines || []) <= 0,
              },
              confirm: "Bekreft innsending av søknaden:",
            },
          ],
        },
        {
          title: "Godkjenning av søknadsbeløp",
          subtitle: "Saksbehandler godkjenner eller avslår søknadsbeløp",
          hidden: !isApplicationStatus(GrantApplicationStatus.SentForApproval) || !isExtendedCourseAdmin,
          props: {
            completeIcon: "mdi-timer-sand",
            complete: true,
          },
          content: [
            {
              component: GrantApplicationDetails,
            },
            {
              component: GrantBudgetTable,
              props: {
                visibleColumns: ["amountAppliedFor", "amountGranted"],
                editableColumns:
                  isExtendedCourseAdmin && isApplicationStatus(GrantApplicationStatus.SentForApproval)
                    ? ["amountGranted"]
                    : [],
              },
            },
            {
              component: GrantBudgetComment,
              disabled: !isExtendedCourseAdmin,
              props: {
                label: "Kommentar til tilsagn",
                hint: !grantValues.value.commentToGrant ? "For å avslå søknaden, må du skrive en kommentar" : undefined,
              },
              eventHandlers: {
                change: (commentToGrant: string) => {
                  grantValues.value = { ...grantValues.value, commentToGrant };
                },
              },
            },
          ],
          actions: [
            {
              label: "Avslå søknad",
              iconLeft: "mdi-close-thick",
              disabled: !isExtendedCourseAdmin,
              eventHandlers: {
                click: async () => {
                  await api.course.rejectFacilitationGrantApplication(+route.params.id, grantValues.value);
                  openNotification(
                    store,
                    NotificationItemType.Success,
                    "Søknad om tilretteleggingstilskudd er avslått"
                  );
                  router.go(0); // FIXME redirect to dashboard?
                },
              },
              props: {
                color: "error",
                outlined: true,
                disabled: !grantValues.value.commentToGrant,
              },
              confirm: "Bekreft avslag av søknaden:",
            },
            {
              label: "Godkjenn søknadsbeløp",
              iconLeft: "mdi-text-box-check",
              disabled: !isExtendedCourseAdmin,
              eventHandlers: {
                click: async () => {
                  await api.course.approveFacilitationGrantApplication(+route.params.id, {
                    commentToGrant: grantValues.value.commentToGrant,
                    costsGranted:
                      grantValues.value.budgetLines?.map(({ budgetLineType, amountGranted }) => ({
                        budgetLineType,
                        amountGranted,
                      })) ?? [],
                  });
                  openNotification(store, NotificationItemType.Success, "Søknadsbeløp er godkjent");
                  nextStep(); // FIXME redirect to dashboard?
                },
              },
              props: {
                color: "success",
                disabled: sumCostType("amountGranted", grantValues.value.budgetLines || []) <= 0,
              },
            },
          ],
        },
        {
          title: "Dokumentasjon på faktiske kostnader",
          hidden: !isApplicationStatus(GrantApplicationStatus.BudgetApproved),
          locked: !isApplicationStatus(GrantApplicationStatus.BudgetApproved),
          subtitle:
            "Regnskapsrapport og dokumentasjon på faktiske ekstrakostnader må lastes opp nedenfor før tilskudd kan utbetales",
          content: [
            {
              component: GrantBudgetTable,
              props: {
                visibleColumns: ["amountAppliedFor", "amountGranted", "amountActualCost"],
                editableColumns: isApplicationStatus(GrantApplicationStatus.BudgetApproved) ? ["amountActualCost"] : [],
              },
            },
            {
              component: GrantDocumentation,
            },
          ],
          // props: {
          //   rules: [() => false], // TODO sett til false om ikke godkjent
          // },
          actions: [
            {
              label: "Send inn dokumentasjon",
              iconRight: "mdi-send",
              eventHandlers: {
                click: async () => {
                  await api.course.addActualCostsToGrantApplication(+route.params.id, {
                    commentToGrant: "",
                    actualCosts:
                      grantValues.value.budgetLines?.map(({ budgetLineType, amountActualCost: actualCost }) => ({
                        budgetLineType,
                        actualCost,
                      })) ?? [],
                  });
                  openNotification(store, NotificationItemType.Success, "Dokumentasjon er innsendt");
                  nextStep(); // FIXME redirect to dashboard?
                },
              },
              props: {
                color: "primary",
              },
            },
          ],
        },
        {
          title: "Godkjenning av faktiske kostnader",
          subtitle: "Saksbehandler godkjenner eller avslår faktiske kostnader",
          props: {
            completeIcon: "mdi-timer-sand",
            complete: true,
          },
          locked: !isApplicationStatus(GrantApplicationStatus.CostAdded) || !isExtendedCourseAdmin,
          hidden: !isApplicationStatus(GrantApplicationStatus.CostAdded) || !isExtendedCourseAdmin,
          content: [
            {
              component: GrantApplicationDetails,
            },
            {
              component: GrantBudgetTable,
            },
            {
              component: GrantBudgetComment,
              disabled: !isExtendedCourseAdmin,
              props: {
                label: "Kommentar til kostnader",
              },
              eventHandlers: {
                change: (comment: string) => {
                  grantValues.value.commentToActualCost = comment;
                },
              },
            },
          ],
          actions: [
            {
              label: "Avslå søknad",
              iconLeft: "mdi-close-thick",
              disabled: !isExtendedCourseAdmin,
              eventHandlers: {
                click: async () => {
                  await api.course.rejectFacilitationGrantApplication(+route.params.id, grantValues.value);
                  openNotification(
                    store,
                    NotificationItemType.Success,
                    "Søknad om tilretteleggingstilskudd er avslått"
                  );
                  router.go(0); // FIXME redirect to dashboard?
                },
              },
              props: {
                color: "error",
                outlined: true,
              },
            },
            {
              label: "Godkjenn faktiske kostnader",
              iconLeft: "mdi-cash-check",
              disabled: !isExtendedCourseAdmin,
              eventHandlers: {
                click: async () => {
                  await api.course.approveActualCostsForFacilitationGrantApplication(+route.params.id, {
                    commentToActualCosts: grantValues.value.commentToActualCost,
                  });
                  openNotification(
                    store,
                    NotificationItemType.Success,
                    "Faktiske kostnader for tilretteleggingstilskudd er godkjent, søknaden om tilretteleggingstilskudd er ferdigbehandlet"
                  );
                  nextStep();
                },
              },
              props: {
                color: "success",
              },
            },
          ],
        },
        {
          title: "Søknad behandlet",
          locked: !isApplicationStatus(GrantApplicationStatus.CostApproved, GrantApplicationStatus.Rejected),
          props: {
            complete: isApplicationStatus(GrantApplicationStatus.CostApproved, GrantApplicationStatus.Rejected),
          },
          content: [
            {
              component: GrantApplicationSummary,
            },
            {
              component: GrantApplicationDetails,
            },
            {
              component: GrantBudgetTable,
            },
          ],
          actions: [],
        },
      ].map((step, index) => ({ ...step, index: index + 1 }))
    );

    const nextStep = () => {
      if (currentStep.value === undefined) {
        currentStep.value = activeStep.value + 1;
        return;
      }
      currentStep.value += 1;
    };

    const prevStep = () => {
      if (currentStep.value === undefined) {
        currentStep.value = activeStep.value - 1;
        return;
      }
      currentStep.value -= 1;
    };

    const setStep = (step: GrantStep) => {
      if (step.locked || step.hidden) {
        return;
      }
      currentStep.value = step.index;
    };

    const isStepVisible = (step: GrantStep) => !step.locked && !step.hidden;

    const getStepProps = (step: GrantStep) => {
      const complete =
        (step.index && activeStep.value > step.index) || (activeStep.value === step.index && step.hidden);
      if (activeStep.value !== step.index) {
        return { complete, completeIcon: "mdi-check" };
      }
    };

    const filterEnabledItems = (items: { disabled?: boolean }[]) => items.filter((item) => !item.disabled);

    const currentStep = ref<number>();

    const activeStep = computed(
      () =>
        currentStep.value ??
        (props.course?.facilitationGrantApplication?.applicationStatus
          ? {
              [GrantApplicationStatus.SentForApproval]: 3,
              [GrantApplicationStatus.BudgetApproved]: 4,
              [GrantApplicationStatus.CostAdded]: 5,
              [GrantApplicationStatus.CostApproved]: 6,
              [GrantApplicationStatus.Rejected]: 6,
            }[props.course.facilitationGrantApplication.applicationStatus] ?? 1
          : 1)
    );

    return {
      activeStep,
      currentStep,
      filterEnabledItems,
      getStepProps,
      grantValues,
      isStepVisible,
      nextStep,
      prevStep,
      setStep,
      steps,
      updateGrant,
    };
  },
});

interface GrantStep {
  index?: number;
  title: string;
  hidden?: boolean;
  locked?: boolean;
  complete?: boolean;
  disabled?: boolean;
  content: {
    component: unknown;
    eventHandlers?: Record<string, unknown>;
  }[];
  props?: Record<string, unknown | unknown[]>;
}
