









































import { api } from "@/api/api";
import {
  ApiCreateFacilitationGrantApplicationDto,
  ApiFacilitationGrantBudgetLineType,
  ApiGetDepartmentDto,
  ApiGetFacilitationGrantApplicationDto,
  ApiGetKursAdminCourseDto,
  ApiGetMemberOrganizationDto,
} from "@/api/generated/Api";
import GrantApplicationStepper from "@/components/course/details/facilitationGrants/GrantApplicationStepper.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { isGrantApplicationEnabled } from "@/shared/helpers/courseHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "CourseFacilitationGrantsPage",
  components: {
    BaseLayout,
    GrantApplicationStepper,
  },
  setup() {
    const route = useRoute();

    const course = ref<ApiGetKursAdminCourseDto>();
    const memberOrganization = ref<ApiGetMemberOrganizationDto | ApiGetDepartmentDto>();
    const facilitationGrant = ref(getFacilitationGrantInitialData());

    const courseId = +route.params.id;
    const courseName = computed(() => course.value?.courseName);

    const parentCourse = computed(() => course.value?.mainCourseId);
    const facilitationCourse = computed(() => course.value?.facilitationCourseId);

    const canCreateLinkedCourse = computed(
      () =>
        !course.value?.mainCourseId &&
        !course.value?.facilitationCourseId &&
        isGrantApplicationEnabled(course.value?.economy?.grantId) &&
        course.value?.status !== CourseStatus.Closed
    );

    const getCourseOrganization = async (lookupOrganizationId: number) => {
      const filter = (organization: { id: string | number }) => +organization.id === lookupOrganizationId;
      return (
        (await api.organization.getMemberOrganizationsAsync()).data.find(filter) ??
        (await api.organization.getDepartmentsAsync()).data.find(filter)
      );
    };

    const loadCourse = async () => {
      course.value = (await api.course.getCourseByIdFilteredByDepartmentAsync(courseId)).data;

      memberOrganization.value = await getCourseOrganization(course.value.organizerOrganizationId);

      if (course.value.facilitationGrantApplication) {
        facilitationGrant.value = course.value.facilitationGrantApplication;
      }
    };

    const createLinkedCourseLink = computed(() => ({
      name: CourseRouteNames.NewCoursePrerequisites,
      query: {
        linkedCourse: courseId.toString(),
        linkedCourseType: "grant",
      },
    }));

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await Promise.all([loadCourse()]);
      });
    });

    return {
      course,
      courseId,
      courseName,
      facilitationGrant,
      formatLocalizedDate,
      memberOrganization,
      createLinkedCourseLink,
      canCreateLinkedCourse,
      parentCourse,
      facilitationCourse,
      SingleCourseRouteNames,
    };
  },
});

const getFacilitationGrantInitialData = ():
  | ApiCreateFacilitationGrantApplicationDto
  | ApiGetFacilitationGrantApplicationDto => ({
  applicationDescription: "",
  descriptionOfAdditionalCost: "",
  targetGroup: "",
  location: "",
  numberOfHours: 0,
  numberOfParticipants: 0,
  startDate: "",
  endDate: "",
  budgetLines: ([...Array(5).keys()] as ApiFacilitationGrantBudgetLineType[]).map((budgetLineType) => ({
    budgetLineType,
    amountAppliedFor: 0,
    amountGranted: 0,
    amountActualCost: 0,
  })),
  commentToGrant: "",
  commentToActualCost: "",
});
