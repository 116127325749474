











































import { api } from "@/api/api";
import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { commonProps } from "./commonProps";

export default defineComponent({
  name: "GrantDocumentation",
  props: {
    ...commonProps,
  },
  setup(props) {
    const uploadedFiles = ref<ApiGetCourseRelatedFileDto[]>([]);
    const filesToUpload = ref<File[]>([]);
    const isLoading = ref(false);

    const loadFiles = async () => {
      isLoading.value = true;
      uploadedFiles.value = (await api.file.getFacilitationGrantApplicationFilesAsync(props.courseId)).data;
      isLoading.value = false;
    };

    const uploadFiles = async () => {
      isLoading.value = true;
      await Promise.all(
        filesToUpload.value.map((file) =>
          api.file.createFacilitationGrantApplicationFileAsync(props.courseId, {
            Category: "Tilretteleggingstilskudd",
            File: file,
            Description: "Opplastet dokumentasjon",
          })
        )
      );
      filesToUpload.value = [];
      await loadFiles();
      isLoading.value = false;
    };

    onMounted(async () => {
      loadFiles();
    });

    return {
      isLoading,
      uploadFiles,
      uploadedFiles,
      filesToUpload,
    };
  },
});
